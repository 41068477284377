import React, { useState, useEffect, useRef, useMemo } from "react";
import { ServiceHeaderButtons } from "./header-buttons";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { classes } from "../../../../data/layouts";
import { TabContent, TabPane } from "reactstrap";
import { ottaxios, servicesaxios } from "../../../../axios";
import Skeleton from "react-loading-skeleton";
import DataTable from "react-data-table-component";
import { logout_From_Firebase } from "../../../../utils";
import {
  getServiceListsTableColumns,
  getAppliedServiceFiltersObj,
} from "../data";
import AddServicePlan from "../addserviceplan";
import { NewServiceDetails } from "../NewServiceDestails";
import NewServiceUtilityBadge from "./status-tabs";
import { toast } from "react-toastify";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../table.css";
import tableHeaders from "./table-headers";

import Row from "./ott-subscribers-row";
import AddOttSubscriber from "./add-ott-subscriber";
import Pagination from "../../../common/pagination/pagination-component";
import PlanDetailsContainer from "./plan-details-container";
import ConfirmationModal from "../../../common/confirmation-modal/ConfirmationModal";
import OTPModal from "../../../common/confirmation-modal/OTPModal";

const OTTSubscribers = () => {
  const [serviceLists, updateserviceLists] = useState({
    currentPageNo: 1,
    currentItemsPerPage: 10,
    pageLoadData: [],
    pageLoadDataForFilter: [],
    prevURI: null,
    nextURI: null,
    currentTab: "ACT",
    tabCounts: {},
    totalRows: 20,
    appliedServiceFilters: { ...getAppliedServiceFiltersObj() },
  });

  const unsortedPageLoadData = useRef();
  const apiResponse = useRef();

  const [showHidePermissionModal, setShowHidePermissionModal] = useState(false);
  const [allItemsSelected, setAllItemsSelected] = useState(false);
  const [activeTab, setActiveTab] = useState("act");
  const [selectedRow, setSelectedRow] = useState({});
  const [isServiceDetailsOpen, setIsServiceDetailsOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [counts, setCounts] = useState({
    active: 0,
    inactive: 0,
  });
  const [isPaginationDisabled, setIsPaginationDisabled] = useState(false);

  const [permissionModalText, setPermissionModalText] = useState(
    "You are not authorized for this action",
  );
  const [loading, setLoading] = useState(false);
  const [sortedColumn, setSortedColumn] = useState({});

  // pagination
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(10);

  // combo buttons
  const [addCombo, setAddCombo] = useState(0);
  // slide panel
  const [activeTab1, setActiveTab1] = useState("1");
  const configDB = useSelector((content) => content.Customizer.customizer);
  const [sidebar_type, setSidebar_type] = useState(
    configDB.settings.sidebar.type,
  );
  const [serviceSelectedCheckboxObjects, setServiceSelectedCheckboxObjects] =
    useState({});
  var updateBysub = false;

  let history = useHistory();
  let DefaultLayout = {};
  const dispatch = useDispatch();

  useEffect(() => {
    const defaultLayoutObj = classes.find(
      (item) => Object.values(item).pop(1) === sidebar_type,
    );

    const id =
      window.location.pathname === "/"
        ? history.push()
        : window.location.pathname.split("/").pop();
    // fetch object by getting URL
    const layoutobj = classes.find((item) => Object.keys(item).pop() === id);
    const layout = id ? layoutobj : defaultLayoutObj;
    DefaultLayout = defaultLayoutObj;
    handlePageLayputs(layout);
  }, []);

  const handlePageLayputs = (type) => {
    let key = Object.keys(type).pop();
    let val = Object.values(type).pop();
    document.querySelector(".page-wrapper").className = "page-wrapper " + val;
    localStorage.setItem("layout", key);
    history.push(key);
  };

  const openCustomizer = (type) => {
    setActiveTab1(type);
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.add("open");
  };

  const closeCustomizer = () => {
    setRightSidebar(!rightSidebar);
    document.querySelector(".customizer-contain").classList.remove("open");
  };
  const togglePermissionModal = () =>
    setShowHidePermissionModal(!showHidePermissionModal);
  // getQueryParams for tabs

  const getQueryParams = () => {
    const { currentPageNo } = serviceLists;
    return currentPageNo;
  };

  // get API
  const fetchOttSubscribers = () => {
    setLoading(true);
    ottaxios
      .get(`ott/subscriber_details/${getQueryParams()}`)
      .then((response) => {
        const {
          data: {
            data: { count, result },
          },
        } = response;
        if (!count) {
          setIsPaginationDisabled(true);
          return;
        }
        setIsPaginationDisabled(false);
        const parsedResults = result.map((row, id) => ({
          ...row,
          id,
        }));
        updateserviceLists((prevState) => ({
          ...prevState,
          pageLoadData: parsedResults,
          pageLoadDataForFilter: [...result],
          totalRows: count,
        }));
        apiResponse.current = parsedResults;
        const activeCount = result.filter(
          (row) => row.status === "active",
        ).length;
        setCounts({
          active: activeCount,
          inactive: result.length - activeCount,
        });
      })
      .catch((error) => {
        console.log("error====", error.response);
        if (error.response.data.message) {
          toast.error(error.response.data.message, { autoClose: 2000 });
          return;
        }
        let errorMessage = "Something went wrong";
        if (error.response && error.response.data) {
          if (error.response.data["package_name"].length > 0) {
            errorMessage = error.response.data["package_name"][0];
          } else if (error.response.status === 500) {
            errorMessage = "Internal server error";
          } else if (error.response.status === 404) {
            errorMessage = "API endpoint not found";
          } else if (error.response.status === 401) {
            errorMessage = "Unauthorized!";
          }
        }
        toast.error(errorMessage, { autoClose: 2000 });
      })

      .finally(function () {
        setLoading(false);
      });
  };

  const verifyOtp = (otp) => {
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    setLoading(true);
    ottaxios
      .post(`/ott/verify_otp`, { vbc_customer_id: customerId, otp }, config)
      .then((res) => {
        if (res.data.status === "failure") {
          toast.error(`Error: ${res.data.message}`, { autoClose: 2000 });
        } else {
          toast.success("OTP Verified!", { autoClose: 2000 });
          onVerifyOtp();
        }
      })
      .catch((error) => {
        console.log("error====", error.response);
        if (error.response.data.message) {
          toast.error(error.response.data.message, { autoClose: 2000 });
          return;
        }
        let errorMessage = "Something went wrong";
        if (error.response && error.response.data) {
          if (error.response.data["package_name"].length > 0) {
            errorMessage = error.response.data["package_name"][0];
          } else if (error.response.status === 500) {
            errorMessage = "Internal server error";
          } else if (error.response.status === 404) {
            errorMessage = "API endpoint not found";
          } else if (error.response.status === 401) {
            errorMessage = "Unauthorized!";
          }
        }
        toast.error(errorMessage, { autoClose: 2000 });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    updateserviceLists((prev) => ({
      ...prev,
      pageLoadData: prev.pageLoadData.map((row) => ({
        ...row,
        isSelected: serviceSelectedCheckboxObjects[row.id],
      })),
    }));
  }, [serviceSelectedCheckboxObjects]);

  useEffect(() => {
    unsortedPageLoadData.current = serviceLists.pageLoadData;
  }, [serviceLists.pageLoadData]);

  const handleServiceSelectedRows = (row, isSelected) => {
    if (!isSelected) setAllItemsSelected(false);
    let selectedCheckboxes = { ...serviceSelectedCheckboxObjects };
    if (isSelected && !selectedCheckboxes[row.id]) {
      selectedCheckboxes[row.id] = true;
    }
    if (!isSelected && selectedCheckboxes[row.id]) {
      delete selectedCheckboxes[row.id];
    }
    setServiceSelectedCheckboxObjects({ ...selectedCheckboxes });
    if (Object.keys(selectedCheckboxes).length === 1) {
      setSelectedRow(row);
    } else setSelectedRow({});
  };

  useEffect(() => {
    fetchOttSubscribers();
  }, [
    serviceLists.currentPageNo,
    serviceLists.currentItemsPerPage,
    refresh,
    serviceLists.appliedServiceFilters,
  ]);

  useEffect(() => {
    updateserviceLists((prev) => ({
      ...prev,
      pageLoadData: (apiResponse.current || []).filter(
        (row) => row.status?.toLowerCase() === activeTab.toLowerCase(),
      ),
    }));
    setServiceSelectedCheckboxObjects({});
    setSelectedRow({});
    setAllItemsSelected(false);
  }, [activeTab]);

  const handleServicePerRowsChange = (newPerPage, page) => {
    updateserviceLists((prevState) => ({
      ...prevState,
      currentPageNo: page,
      currentItemsPerPage: newPerPage,
    }));
  };

  const handleServicePageChange = (page) => {
    updateserviceLists((prevState) => ({
      ...prevState,
      currentPageNo: page,
    }));
  };

  // side panel
  const serviceIdClickHandler = (row) => {
    setSelectedRow(row);
    setIsServiceDetailsOpen(true);
  };

  const closeCustomizer1 = () => {
    setRightSidebar(!rightSidebar);
    setIsServiceDetailsOpen(false);
  };

  const detailsUpdate = () => {
    RefreshHandler();
    closeCustomizer();
  };

  const onAddSubscriber = (customerId) => {
    setCustomerId(customerId);
    setShowOTPModal(true);
    // RefreshHandler();
    // closeCustomizer();
  };

  const onVerifyOtp = () => {
    setShowOTPModal(false);
    closeCustomizer();
    RefreshHandler();
  };

  const logout = () => {
    logout_From_Firebase();
    history.push(`${process.env.PUBLIC_URL}/login`);
  };
  const searchInputField = useRef(null);

  //refresh page
  const RefreshHandler = () => {
    setRefresh((prevValue) => prevValue + 1);
    if (searchInputField.current) searchInputField.current.value = "";
  };

  const callAPIforActiveInctive = () => {
    // var planList = [];
    // var selectedCheckboxes = { ...serviceSelectedCheckboxObjects };
    // for (let key in selectedCheckboxes) {
    //   var newpageLoadData = [...serviceLists.pageLoadData];
    //   var currentRow = newpageLoadData.find((a) => a.id == key);
    //   if (currentRow && !!currentRow.sub_plans) {
    //     if (selectedCheckboxes[key].length == currentRow.sub_plans.length) {
    //       planList = [...planList, ...selectedCheckboxes[key], key];
    //     } else {
    //       planList = [...planList, ...selectedCheckboxes[key]];
    //     }
    //   } else {
    //     planList = [...planList, key];
    //   }
    // }
    // const formData = {
    //   plans: [...planList],
    //   status: activeTab == "IN" ? "ACT" : "IN",
    // };
    // var config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };
    // servicesaxios
    //   .post("/plans/status/change", formData, config)
    //   .then((response) => {
    //     console.log(response.data);
    //     // props.onUpdate(response.data);
    //     // Sailaja Modified Toast message from plan update successfully to Plan updated successfully on 28th March 2023
    //     toast.success("Plan updated successfully", {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });
    //     RefreshHandler();
    //   })
    //   .catch(function (error) {
    //     toast.error("Something went wrong!", {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });
    //     console.error("Something went wrong!", error);
    //     // this.setState({ errorMessage: error });
    //   });
  };

  const sortDataByColumn = (displayName, columnName, cb) => {
    let sortedData = [];
    let order = "asc";

    if (sortedColumn.name === displayName) {
      order = sortedColumn.order === "asc" ? "des" : "asc";
      if (order === "asc") {
        sortedData = [...unsortedPageLoadData.current].sort((a, b) => {
          if (cb) {
            return cb(a) >= cb(b) ? 1 : -1;
          } else return a[columnName] >= b[columnName] ? 1 : -1;
        });
      } else {
        sortedData = [...unsortedPageLoadData.current].sort((a, b) => {
          if (cb) {
            return cb(a) <= cb(b) ? 1 : -1;
          } else return a[columnName] <= b[columnName] ? 1 : -1;
        });
      }
    } else {
      sortedData = [...unsortedPageLoadData.current].sort((a, b) => {
        if (cb) {
          return cb(a) >= cb(b) ? 1 : -1;
        } else return a[columnName] >= b[columnName] ? 1 : -1;
      });
    }
    setSortedColumn({ name: displayName, order });
    updateserviceLists((prev) => ({
      ...prev,
      pageLoadData: [...sortedData],
    }));
  };

  const sortData = (name) => {
    switch (name) {
      case "Package Name": {
        sortDataByColumn("Package Name", "plan_name");
        break;
      }
      case "Duration": {
        sortDataByColumn("Duration", "plan_duration");
        break;
      }
      case "Plan Cost": {
        sortDataByColumn("Plan Cost", "basic");
        break;
      }
      case "Plan Tax": {
        sortDataByColumn("Plan Tax", "tax");
        break;
      }
      case "Total Cost": {
        sortDataByColumn("Total Cost", "total");
        break;
      }
    }
  };

  const getSortIconName = (name) => {
    if (sortedColumn.name === name) {
      return sortedColumn.order === "asc" ? "down" : "up";
    }
    return "up";
  };

  const handleSelectAll = (selectAll) => {
    const checkboxObjects = (serviceLists.pageLoadData || []).reduce(
      (acc, row) => {
        acc[row.id] = true;
        return acc;
      },
      {},
    );
    setServiceSelectedCheckboxObjects(selectAll ? checkboxObjects : {});
    setAllItemsSelected(selectAll);
  };

  const resendOtp = () => {
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    setLoading(true);
    ottaxios
      .post(`/ott/resend_otp`, { vbc_customer_id: customerId }, config)
      .then((res) => {
        if (res.data.status === "failure") {
          toast.error(`Error: ${res.data.message}`, { autoClose: 2000 });
        } else {
          toast.success("OTP Resent!", { autoClose: 1000 });
        }
      })
      .catch((error) => {
        console.log("error====", error.response);
        if (error.response.data.message) {
          toast.error(error.response.data.message, { autoClose: 2000 });
          return;
        }
        let errorMessage = "Something went wrong";
        if (error.response && error.response.data) {
          if (error.response.data["package_name"].length > 0) {
            errorMessage = error.response.data["package_name"][0];
          } else if (error.response.status === 500) {
            errorMessage = "Internal server error";
          } else if (error.response.status === 404) {
            errorMessage = "API endpoint not found";
          } else if (error.response.status === 401) {
            errorMessage = "Unauthorized!";
          }
        }
        toast.error(errorMessage, { autoClose: 2000 });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div style={{ paddingTop: "20px" }}>
        <Grid container spacing={1} id="breadcrumb_margin">
          <Grid item md="12">
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <NavigateNextIcon fontSize="small" className="navigate_icon" />
              }
            >
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color=" #377DF6"
                fontSize="14px"
              >
                Customer Relations
              </Typography>
              {/* Sailaja Changed  Service Plan Color from Breadcrumbs  on 13th July */}

              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color=" #00000"
                fontSize="14px"
                className="last_typography"
              >
                Customers
              </Typography>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="#00000 !important"
                fontSize="14px"
                className="last_typography"
              >
                OTT Subscribers
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid
          container
          spacing={1}
          className="data_table"
          id="breadcrumb_table"
        >
          <Grid item md="12">
            <ServiceHeaderButtons
              serviceLists={serviceLists}
              openCustomizer={openCustomizer}
              RefreshHandler={RefreshHandler}
              setAddCombo={setAddCombo}
              addCombo={addCombo}
              activeTab={activeTab}
              updateserviceLists={updateserviceLists}
            />
          </Grid>

          {/* <Grid item md="12">
            <NewServiceUtilityBadge
              currentTab={activeTab}
              setActiveTab={setActiveTab}
              tabCounts={counts}
            />
          </Grid> */}
          <Grid item md="12" style={{ overflowX: "scroll" }}>
            {loading ? (
              <SkeletonLoader loading={loading} />
            ) : (
              <table
                width="100%"
                style={{ borderRadius: "50px", paddingRight: "20px" }}
              >
                <thead>
                  <tr className="table-header-row">
                    {tableHeaders.map((header, idx) => (
                      <th
                        className="table-header"
                        style={{
                          textAlign: "center",
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                        key={idx}
                      >
                        <div className="flex" style={{ textAlign: "center" }}>
                          <div>{header.name}</div>
                          {header.sortable ? (
                            <div
                              onClick={() => sortData(header.name)}
                              className={`${getSortIconName(
                                header.name,
                              )}-arrow`}
                            />
                          ) : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {(serviceLists.pageLoadData || []).map((row) => (
                    <Row
                      key={row.id}
                      row={row}
                      onSelectRow={handleServiceSelectedRows}
                      serviceIdClickHandler={serviceIdClickHandler}
                    />
                  ))}
                </tbody>
              </table>
            )}
          </Grid>
          <Grid item md="12">
            <Pagination
              currentPageNo={serviceLists.currentPageNo}
              currentRowsPerPage={serviceLists.currentItemsPerPage}
              handlePageChange={handleServicePageChange}
              handleRowsPerPageChange={handleServicePerRowsChange}
              totalCount={serviceLists.totalRows}
              // isPaginationDisabled={isPaginationDisabled}
            />
          </Grid>

          <Grid>
            {isServiceDetailsOpen && (
              <PlanDetailsContainer
                detailsUpdate={detailsUpdate}
                isServiceDetailsOpen={isServiceDetailsOpen}
                closeCustomizer1={closeCustomizer1}
                selectedRow={selectedRow}
                RefreshHandler={RefreshHandler}
              />
            )}
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item md="12">
            <div
              className="customizer-contain"
              style={{
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
              }}
            >
              <div className="tab-content" id="c-pills-tabContent">
                <div
                  className="customizer-header"
                  style={{
                    border: "none",
                    padding: "30px 25px",
                    borderTopLeftRadius: "20px",
                  }}
                >
                  <i
                    className="icon-close"
                    style={{ position: "absolute", top: "20px" }}
                    onClick={closeCustomizer}
                  ></i>
                </div>
              </div>

              <div className="tab-content" id="c-pills-tabContent">
                <div className=" customizer-body custom-scrollbar">
                  <TabContent activeTab={activeTab1}>
                    <TabPane tabId="2">
                      <div id="headerheading"> New </div>
                      <ul
                        className="layout-grid layout-types"
                        style={{ border: "none" }}
                      >
                        <li
                          data-attr="compact-sidebar"
                          onClick={(e) => handlePageLayputs(classes[0])}
                        >
                          <div className="layout-img">
                            {activeTab1 == "2" && (
                              <AddOttSubscriber
                                dataClose={closeCustomizer}
                                onUpdate={onAddSubscriber}
                                rightSidebar={rightSidebar}
                              />
                            )}
                          </div>
                        </li>
                      </ul>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <OTPModal
          showModal={showOTPModal}
          setShowModal={setShowOTPModal}
          onSubmit={verifyOtp}
          onResend={resendOtp}
        />
      </div>
    </>
  );
};
const SkeletonLoader = ({ loading }) => {
  const tableData = useMemo(
    () => (loading ? Array(10).fill({}) : []),
    [loading],
  );

  return (
    <Box sx={{ width: "100vw", pl: 2, pr: 2 }}>
      {tableData.map((_) => (
        <Skeleton height={50} />
      ))}
    </Box>
  );
};
export default OTTSubscribers;
