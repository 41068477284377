import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import useFormValidation from "../../../customhooks/FormValidation";
import { ottaxios } from "../../../../axios";

import { toast } from "react-toastify";

import ErrorModal from "../../../common/ErrorModal";
import { COMBO_PLANS } from "../../../../utils/permissions";

const requiredFields = [
  "combo_plan_name",
  "combo_plan_code",
  "basic",
  "tax",
  "total",
  "ott_plan",
  "internet_plan",
  "status",
  "franchise_name",
];

const AddComboPlan = (props) => {
  const [isShow, setIsShow] = React.useState(false);
  const [loaderSpinneer, setLoaderSpinner] = useState(false);
  const [franchiseNames, setFranchiseNames] = useState([]);
  const [formData, setFormData] = useState({
    combo_plan_name: "",
    combo_plan_code: "",
    basic: "",
    tax: "",
    total: "",
    status: "active",
    ott_plan: {},
    internet_plan: {},
    plan_status: "active",
    franchise_name: "",
  });

  const [internetAndOttPlans, setInternetAndOttPlans] = useState({});

  const [ottPlans, setOttPlans] = useState(internetAndOttPlans.ott || []);
  const [internetPlans, setInternetPlans] = useState([]);

  const [errors, setErrors] = useState({});
  const [togglesnmpState, setTogglesnmpState] = useState("off");
  const [isDisabled, setDisabled] = useState(true);
  const [buttonDisabled, seButtonDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [alertMessage, setAlertMessage] = useState(false);
  const handleInputChange = (e, isNumeric = false) => {
    setFormData({
      ...formData,
      [e.target.name]: ["ott_plan", "internet_plan"].includes(e.target.name)
        ? JSON.parse(e.target.value)
        : isNumeric
          ? +Number(Math.abs(e.target.value)).toFixed(2)
          : e.target.value,
    });
  };

  const hasPermission = JSON.parse(
    localStorage.getItem("token") || "{}",
  )?.permissions.includes(COMBO_PLANS.GET_PLANS);

  useEffect(() => {
    setOttPlans(internetAndOttPlans.ott || []);
  }, [internetAndOttPlans]);

  useEffect(() => {
    ottaxios
      .get("ott/get_operator_franchise_names")
      .then((res) => {
        const {
          data: { status, data },
        } = res;
        if (status === "failure") {
          toast.error(`Error: ${res.data.message}`, { autoClose: 2000 });
          return;
        }
        setFranchiseNames(data);
      })
      .catch((error) => {
        setLoaderSpinner(false);
        if (error.response.data.message) {
          toast.error(error.response.data.message, { autoClose: 2000 });
          return;
        }
        let errorMessage = "Something went wrong";
        if (error.response && error.response.data) {
          if (error.response.data["package_name"].length > 0) {
            errorMessage = error.response.data["package_name"][0];
          } else if (error.response.status === 500) {
            errorMessage = "Internal server error";
          } else if (error.response.status === 404) {
            errorMessage = "API endpoint not found";
          } else if (error.response.status === 401) {
            errorMessage = "Unauthorized!";
          }
        }
        toast.error(errorMessage, { autoClose: 2000 });
      });
  }, []);

  const fetchInternetAndOttPlans = async () => {
    if (!hasPermission) return;
    ottaxios
      .get(`combo/get_io_plans/${formData.franchise_name}`)
      .then((response) => {
        const {
          data: { status, result },
        } = response;
        setInternetAndOttPlans(result);
      })
      .catch((error) => {
        console.log("error====", error.response);
        if (error.response.data.message) {
          toast.error(error.response.data.message, { autoClose: 2000 });
          return;
        }
        let errorMessage = "Something went wrong";
        if (error.response && error.response.data) {
          if (error.response.data["package_name"].length > 0) {
            errorMessage = error.response.data["package_name"][0];
          } else if (error.response.status === 500) {
            errorMessage = "Internal server error";
          } else if (error.response.status === 404) {
            errorMessage = "API endpoint not found";
          } else if (error.response.status === 401) {
            errorMessage = "Unauthorized!";
          }
        }
        toast.error(errorMessage, { autoClose: 2000 });
      });
  };

  useEffect(() => {
    const hasEmptyValue = requiredFields.some(
      (field) => formData[field] === "",
    );
    if (hasEmptyValue || Object.keys(errors).length) setDisabled(true);
    else setDisabled(false);
  }, [errors, formData]);

  const resetForm = function () {
    setFormData({
      combo_plan_name: "",
      combo_plan_code: "",
      basic: "",
      tax: "",
      total: "",
      plan_duration: "",
      ott_plan: {},
      internet_plan: {},
      franchise_name: "",
      plan_status: "active",
    });
    setErrors({});
  };

  const form = useRef(null);

  const createPlan = (data) => {
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    setLoaderSpinner(true);
    ottaxios
      .post("/combo/create_plan", data, config)
      .then((res) => {
        if (res.data.status === "failure") {
          toast.error(`Error: ${res.data.message}`, { autoClose: 2000 });
        } else {
          toast.success("Combo Plan Created Successfully!", {
            autoClose: 2000,
          });
          props.onUpdate();
        }
        setLoaderSpinner(false);
      })
      .catch((error) => {
        setLoaderSpinner(false);
        if (error.response.data.message) {
          toast.error(error.response.data.message, { autoClose: 2000 });
          return;
        }
        let errorMessage = "Something went wrong";
        if (error.response && error.response.data) {
          if (error.response.data["package_name"].length > 0) {
            errorMessage = error.response.data["package_name"][0];
          } else if (error.response.status === 500) {
            errorMessage = "Internal server error";
          } else if (error.response.status === 404) {
            errorMessage = "API endpoint not found";
          } else if (error.response.status === 401) {
            errorMessage = "Unauthorized!";
          }
        }
        toast.error(errorMessage, { autoClose: 2000 });
      });
  };

  // validations
  const submit = (e) => {
    e.preventDefault();
    e = e.target.name;
    const {
      combo_plan_name,
      combo_plan_code,
      status,
      plan_status,
      basic,
      tax,
      total,
      ott_plan,
      internet_plan,
      franchise_name,
    } = formData;

    if (ott_plan.plan_duration !== internet_plan.plan_duration) {
      toast.error(
        "Selected OTT and Internet plans must be of the same duration",
        { autoClose: 3000 },
      );
      return;
    }

    const data = {
      combo_plan_name,
      combo_plan_code,
      plan_status: status,
      basic,
      tax,
      total,
      ott_plan_name: ott_plan.plan_name,
      ott_plan_code: ott_plan.plan_code,
      internet_plan_name: internet_plan.plan_name,
      franchise_name,
    };

    const validationErrors = validate(formData);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);

    if (noErrors) {
      setAlertMessage(!alertMessage);
      createPlan(data);
    }

    // else {
    //   toast.error("Something went wrong");
    // }
  };

  const { validate } = useFormValidation(requiredFields);

  const checkFieldValidity = (fieldName) => {
    const validationErrors = validate(formData);
    let vErrors = {};
    if (validationErrors[fieldName]) {
      vErrors[fieldName] = validationErrors[fieldName];
    }

    const noErrors = Object.keys(vErrors).length === 0;

    if (noErrors) {
      const newErrors = { ...errors };
      delete newErrors[fieldName];
      setErrors(newErrors);
    } else setErrors({ ...errors, ...{ [fieldName]: vErrors[fieldName] } });
  };

  function checkEmptyValue(e) {
    if (e.target.value == "") {
      e.target.classList.remove("not-empty");
    } else {
      e.target.classList.add("not-empty");
    }
  }

  //This function will be used for validation of individual fields
  const handleInputBlur = (e, fieldName) => {
    checkEmptyValue(e);
    checkFieldValidity(fieldName);
  };

  useEffect(() => {
    const { total } = formData;
    if (total) {
      setFormData((prev) => {
        const total = Number(prev.total);
        const basic = (total * 100) / 118;
        return {
          ...prev,
          tax: Number(total - basic).toFixed(2),
          basic: Number(basic).toFixed(2),
        };
      });
    } else if (total == 0) {
      setFormData((prev) => ({
        ...prev,
        tax: "",
        basic: "",
        total: "",
      }));
    }
  }, [formData.total]);

  useEffect(() => {
    if (formData.franchise_name) fetchInternetAndOttPlans();
  }, [formData.franchise_name]);

  useEffect(() => {
    if (formData.franchise_name && Object.keys(formData.ott_plan)) {
      const duration = formData.ott_plan.plan_duration;
      setInternetPlans(
        (internetAndOttPlans.internet || []).filter(
          (plan) => plan.plan_duration === duration,
        ),
      );
    }
  }, [formData.ott_plan, formData.franchise_name]);

  return (
    <Fragment>
      <br />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form onSubmit={submit} id="myForm" onReset={resetForm} ref={form}>
              <Row>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Plan Name *</Label>
                      <Input
                        type="text"
                        name="combo_plan_name"
                        onChange={handleInputChange}
                        onBlur={(e) => handleInputBlur(e, "combo_plan_name")}
                        // onBlur={checkEmptyValue}
                        // draft
                        className={`form-control digits ${
                          formData && !formData.combo_plan_name
                            ? ""
                            : "not-empty"
                        }`}
                        value={formData && formData.combo_plan_name}
                      />
                    </div>

                    <span className="errortext">{errors.combo_plan_name}</span>

                    <Row style={{ marginTop: "-5%", marginLeft: "-1px" }}>
                      <Col sm="12">
                        <ul>
                          <li
                            className="nas_field_strength"
                            style={{
                              marginLeft: "-18%",
                              top: "3px",
                              marginBottom: "2%",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <b>Note : Only alphanumeric and "_" are allowed.</b>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Plan Code *</Label>
                      <Input
                        type="text"
                        name="combo_plan_code"
                        onChange={handleInputChange}
                        onBlur={(e) => handleInputBlur(e, "combo_plan_code")}
                        // onBlur={checkEmptyValue}
                        // draft
                        className={`form-control digits ${
                          formData && !formData.combo_plan_code
                            ? ""
                            : "not-empty"
                        }`}
                        value={formData && formData.combo_plan_code}
                      />
                    </div>

                    <span className="errortext">{errors.combo_plan_code}</span>
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Total *</Label>
                      <Input
                        type="number"
                        name="total"
                        onChange={(e) => handleInputChange(e, true)}
                        onBlur={(e) => handleInputBlur(e, "total")}
                        className={`form-control digits ${
                          formData && !formData.total ? "" : "not-empty"
                        }`}
                        min="0"
                        value={formData?.total || ""}
                      />
                    </div>

                    <span className="errortext">{errors.total}</span>
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Plan Cost *</Label>
                      <Input
                        type="number"
                        name="basic"
                        onChange={(e) => handleInputChange(e, true)}
                        onBlur={(e) => handleInputBlur(e, "basic")}
                        className={`form-control digits ${
                          formData && !formData.basic ? "" : "not-empty"
                        }`}
                        min={0}
                        disabled
                        value={formData?.basic || ""}
                      />
                    </div>

                    <span className="errortext">{errors.basic}</span>
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Tax *</Label>
                      <Input
                        type="number"
                        name="tax"
                        onChange={(e) => handleInputChange(e, true)}
                        onBlur={(e) => handleInputBlur(e, "tax")}
                        className={`form-control digits ${
                          formData && !formData.tax ? "" : "not-empty"
                        }`}
                        min="0"
                        value={formData?.tax || ""}
                        disabled
                      />
                    </div>

                    <span className="errortext">{errors.tax}</span>
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Franchise Name *</Label>
                      <Input
                        type="select"
                        name="franchise_name"
                        className="form-control digits"
                        onChange={handleInputChange}
                        onBlur={(e) => handleInputBlur(e, "franchise_name")}
                      >
                        <option value={"{}"}>Select</option>
                        {franchiseNames.map((name) => (
                          <option value={name}>{name}</option>
                        ))}
                      </Input>
                    </div>
                    <span className="errortext">{errors.franchise_name}</span>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">OTT Plan *</Label>
                      <Input
                        type="select"
                        name="ott_plan"
                        className="form-control digits"
                        onChange={handleInputChange}
                        onBlur={(e) => handleInputBlur(e, "ott_plan")}
                      >
                        <option value={"{}"}>Select</option>
                        {ottPlans.map((plan) => (
                          <option value={JSON.stringify(plan)}>
                            {`${plan.plan_name} (${plan.plan_duration}m)`}
                          </option>
                        ))}
                      </Input>
                    </div>
                    <span className="errortext">{errors.ott_plan}</span>
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Internet Plan *</Label>

                      <Input
                        type="select"
                        name="internet_plan"
                        className="form-control digits"
                        onChange={handleInputChange}
                        onBlur={(e) => handleInputBlur(e, "internet_plan")}
                      >
                        <option value={"{}"}>Select</option>
                        {internetPlans.map((plan) => (
                          <option value={JSON.stringify(plan)}>
                            {`${plan.plan_name} (${plan.plan_duration}m)`}
                          </option>
                        ))}
                      </Input>
                    </div>
                    <span className="errortext">{errors.internet_plan}</span>
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Status *</Label>

                      <Input
                        type="select"
                        name="status"
                        className="form-control digits"
                        onChange={handleInputChange}
                        onBlur={(e) => handleInputBlur(e, "status")}
                      >
                        <option value="active">ACTIVE</option>
                        <option value="inactive">INACTIVE</option>
                      </Input>
                    </div>
                    <span className="errortext">{errors.status}</span>
                  </FormGroup>
                </Col>
              </Row>

              <br />
              <Row>
                <span
                  className="sidepanel_border"
                  style={{ position: "relative" }}
                ></span>
                <Col>
                  <FormGroup className="mb-0">
                    <Button
                      color="btn btn-primary"
                      className="mr-3"
                      onClick={submit}
                      id="create_button"
                      disabled={loaderSpinneer ? loaderSpinneer : isDisabled}
                    >
                      {loaderSpinneer ? (
                        <Spinner size="sm" id="spinner"></Spinner>
                      ) : null}
                      Create
                    </Button>

                    <Button type="reset" color="btn btn-primary" id="resetid">
                      Reset
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <ErrorModal
          isOpen={showModal}
          toggle={() => setShowModal(false)}
          message={modalMessage}
          action={() => setShowModal(false)}
        />
      </Container>
    </Fragment>
  );
};

export default AddComboPlan;
