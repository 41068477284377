import React, { Fragment } from "react";
import { Container, Row, Col, Label, FormGroup } from "reactstrap";
import OttIcon from "../../../../assets/images/ott-icon.png";

const PlanDetails = ({ plan }) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <TextField name="Plan Code" value={plan.plan_code} />
          <TextField
            name="Duration"
            value={
              plan.plan_duration +
              " " +
              "Month" +
              (plan.plan_duration > 1 ? "s" : "")
            }
          />
          <TextField name="Status" value={plan.status} />
          <TextField name="Plan Cost" value={plan.basic} />
          <TextField name="Plan Tax" value={plan.tax} />
          <TextField name="Total Cost" value={plan.total} />
          {/* <TextField
            textarea
            name="OTT Services"
            value={plan.ott_services.toString().split(",").join(" , ")}
          /> */}
        </Row>
        <Row>
          <div style={{ marginTop: 30, fontSize: "16px" }}>
            <div>Ott Services</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 10,
              }}
            >
              {(plan.ott_services || []).map((ott, idx) => (
                <div
                  key={idx}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 10,
                    marginRight: 20,
                  }}
                >
                  <img
                    style={{
                      objectFit: "contain",
                      height: "35px",
                      width: "35px",
                    }}
                    src={OttIcon}
                    alt="ott-icon"
                  />
                  {ott}
                </div>
              ))}
            </div>
          </div>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PlanDetails;

const NumericField = () => (
  <Col md="3" id="moveup">
    <FormGroup>
      <div className="input_wrap">
        <Label className="kyc_label">Plan CGST</Label>
        <input
          className={`form-control digits not-empty`}
          id="afterfocus"
          type="number"
          name="plan_cgst"
          min="0"
          onKeyDown={(evt) =>
            (evt.key === "e" ||
              evt.key === "E" ||
              evt.key === "." ||
              evt.key === "-") &&
            evt.preventDefault()
          }
          style={{ border: "none", outline: "none" }}
          // value={leadUser && leadUser.plan_cgst}
          disabled={true}
        ></input>
      </div>
    </FormGroup>
  </Col>
);

const TextField = ({ name, value, textarea }) => (
  <FormGroup>
    <div className="input_wrap ml-3">
      <Label className="kyc_label" style={{ fontSize: "16px" }}>
        {name}
      </Label>
      {textarea ? (
        <textarea
          id="afterfocus"
          type="text"
          name="package_name"
          value={value}
          disabled={true}
          style={{ height: 100, fontSize: "16px" }}
        />
      ) : (
        <input
          className={`form-control digits not-empty`}
          id="afterfocus"
          type="text"
          name="package_name"
          style={{ border: "none", outline: "none", fontSize: "16px" }}
          value={value}
          disabled={true}
        ></input>
      )}
    </div>
  </FormGroup>
);
