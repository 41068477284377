import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

import Renew from "./renew1";
import ChangePlan from "./changeplan1";
import isEmpty from "lodash/isEmpty";
import { adminaxios } from "../../../axios";
import {
  COMBO_PLANS,
  CUSTOMER_LIST,
  OTT_SUBSCRIBERS,
} from "../../../utils/permissions";
import UpgradeOPtion from "./upgrade";
var storageToken = localStorage.getItem("token");
if (storageToken !== null) {
  var token = JSON.parse(storageToken);
}

export const NewCustomerListsRenewChangePlanModal = (props) => {
  const {
    isRenewChangePlanModalOpen,
    toggleRenewChangePlanModalOpen,
    serviceObjData,
    setServiceObjData,
    RefreshHandler,
    selectID,
    changeplan,
    setChangeplan,
    changeplanListBkp,
    setChangeplanListBkp,
    serviceplanobj,
    setServiceplanobj,
    serviceplanobjbkp,
    setServiceplanobjbkp,
    setUpdateInfoCount,
  } = props;
  const [currentPlan, setCurrentPlan] = useState({});
  // Sailaja Created a new object to replace hardcoded heading, when you select Renew/ Change Plan/ Upgrade Plan on 3rd April 2023
  const NameChange = {
    renew: "Renew Plan",
    changeplan: "Change Plan",
  };

  const [upgradeRenewChangePlan, setUpgradeRenewChangePlan] = useState("renew");

  useEffect(() => {
    if (isEmpty(serviceObjData)) setUpgradeRenewChangePlan("renew");
    if (!props.isOtt) setUpgradeRenewChangePlan("changeplan");

    let customerInfo = JSON.parse(sessionStorage.getItem("customerInfDetails"));
    if (customerInfo) {
      datasubmit(customerInfo.service_plan, customerInfo.area_id);
      changeplanSubmit(customerInfo.service_plan, customerInfo.area_id);
    }
  }, [isRenewChangePlanModalOpen, props.isOtt]);

  //upgrade plan

  const datasubmit = (id, area) => {
    if (isRenewChangePlanModalOpen && !renderAfterCalled) {
      adminaxios
        .get(`accounts/loggedin/${area}/plans/${id}`)
        .then((res) => {
          setServiceplanobj(res.data);
          setServiceplanobjbkp(res.data);
        })
        .catch(function (error) {});
    }
  };

  // change plan
  const renderAfterCalled = useRef(false);

  const changeplanSubmit = (id, area) => {
    if (isRenewChangePlanModalOpen && !renderAfterCalled) {
      let customerInfo = JSON.parse(
        sessionStorage.getItem("customerInfDetails"),
      );
      adminaxios
        .get(`accounts/area/${area}/otherplans/${id}/${customerInfo.id}`)
        .then((res) => {
          setChangeplan(res.data);
          setChangeplanListBkp(res.data);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isRenewChangePlanModalOpen}
        toggle={toggleRenewChangePlanModalOpen}
        centered
        className="renew-change-plan-modal"
      >
        <ModalBody
          className="renew-change-plan-modal-body"
          style={{
            padding: "35px",
            maxHeight: "600px",
            overflow: "auto",
          }}
        >
          <ModalHeader style={{ padding: "1rem 0rem" }}>
            <b style={{ fontSize: "20px" }}>
              {/* Renew Plan or Change Plan : {customerInfo?.username}
               */}
              {/* Renew Plan or Change Plan : {JSON.parse(localStorage.getItem("customerInfDetails")) && JSON.parse(localStorage.getItem("customerInfDetails")).username} */}
              {/* Sailaja Modified above hardcoded name to dynamically changenames when you click on Renew/ Change Plan/ Upgrade Plan on 3rd April 2023              {NameChange[upgradeRenewChangePlan]} : {JSON.parse(localStorage.getItem("customerInfDetails")) && JSON.parse(localStorage.getItem("customerInfDetails")).username} */}
              {NameChange[upgradeRenewChangePlan]} : {props.customerId}
            </b>
          </ModalHeader>
          {props.isOtt && (
            <Row>
              <Col>
                <FormGroup
                  className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml"
                  style={{ display: "flex", marginLeft: "0px" }}
                >
                  {token.permissions.includes(CUSTOMER_LIST.RENEW_PLAN) && (
                    <div>
                      <Input
                        className="radio_animated"
                        id="radioinlinerenew"
                        type="radio"
                        name="renew"
                        value="renew"
                        checked={upgradeRenewChangePlan === "renew"}
                        onClick={() => setUpgradeRenewChangePlan("renew")}
                      />

                      <Label className="mb-0" for="radioinlinerenew">
                        {Option}
                        <span className="digits"> {"Renew"}</span>
                      </Label>
                    </div>
                  )}
                  {token.permissions.includes(CUSTOMER_LIST.CHANGE_PLAN) && (
                    <>
                      <div>
                        <Input
                          className="radio_animated"
                          id="radioinlinechange"
                          type="radio"
                          name="changeplan"
                          value="changeplan"
                          checked={upgradeRenewChangePlan === "changeplan"}
                          onClick={() =>
                            setUpgradeRenewChangePlan("changeplan")
                          }
                        />

                        <Label className="mb-0" for="radioinlinechange">
                          {Option}
                          <span className="digits">{"Change Plan"}</span>
                        </Label>
                      </div>
                    </>
                  )}
                </FormGroup>
              </Col>
            </Row>
          )}
          {props.isOtt && upgradeRenewChangePlan === "renew" ? (
            <>
              {token.permissions.includes(CUSTOMER_LIST.RENEW_PLAN) && (
                <Renew
                  setCurrentPlan={setCurrentPlan}
                  Refreshhandler={RefreshHandler}
                  serviceobj={isRenewChangePlanModalOpen}
                  Verticalcentermodaltoggle1={toggleRenewChangePlanModalOpen}
                  setServiceobjdata={setServiceObjData}
                  serviceobjdata={serviceObjData}
                  serviceplanobj={serviceplanobj}
                  id={selectID}
                  fetchComplaints={props.fetchComplaints}
                  customerId={props.customerId}
                  isOtt={props.isOtt}
                  walletAmount={props.walletAmount}
                />
              )}
            </>
          ) : (
            <>
              {token.permissions.includes(CUSTOMER_LIST.CHANGE_PLAN) && (
                <ChangePlan
                  upgradeRenewChangePlan={upgradeRenewChangePlan}
                  Refreshhandler={RefreshHandler}
                  serviceobj={isRenewChangePlanModalOpen}
                  Verticalcentermodaltoggle1={toggleRenewChangePlanModalOpen}
                  setServiceobjdata={setServiceObjData}
                  serviceobjdata={serviceObjData}
                  changeplanlist={changeplan}
                  changeplanlistBkp={changeplanListBkp}
                  setChangeplan={setChangeplan}
                  id={selectID}
                  setUpdateInfoCount={setUpdateInfoCount}
                  fetchComplaints={props.fetchComplaints}
                  customerId={props.customerId}
                  isOtt={props.isOtt}
                  walletAmount={props.walletAmount}
                />
              )}
            </>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
