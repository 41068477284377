import React, { useState } from "react";
import "./confirmation-modal.css";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

const OTPModal = ({ showModal, setShowModal, onResend, onSubmit }) => {
  const [otp, setOtp] = useState();
  const [disabled, setDisabled] = useState(false);

  const handleResendPress = () => {
    onResend();
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, [1000 * 60]);
  };

  return showModal ? (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 100,
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
      }}
    >
      <div className="confirmation-modal">
        <div
          style={{
            fontSize: 20,
            padding: 10,
            paddingRight: 20,
            textAlign: "right",
            cursor: "pointer",
          }}
          onClick={() => setShowModal(false)}
        >
          &#x2715;
        </div>
        <div
          className="confirmation-modal-content"
          style={{ justifyContent: "center" }}
        >
          <Form id="myForm" style={{ justifyContent: "center" }}>
            <Row style={{ justifyContent: "center" }}>
              <FormGroup>
                <div className="input_wrap">
                  <Label
                    className="kyc_label"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Enter OTP
                  </Label>
                  <Input
                    type="text"
                    name="otp"
                    onChange={(e) => setOtp(e.target.value)}
                    className={"form-control digits"}
                    value={otp}
                    maxLength={6}
                    minLength={6}
                  />
                </div>
              </FormGroup>
            </Row>
          </Form>
        </div>
        <div className="confirmation-modal-footer">
          <div
            className="modal-button cancel"
            disabled={disabled}
            onClick={handleResendPress}
          >
            {disabled ? "Resend again in 1 min" : "Resend OTP"}
          </div>
          <div className="modal-button confirm" onClick={() => onSubmit(otp)}>
            Submit
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default OTPModal;
