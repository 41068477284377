const servicePlanTableHeaders = [
  { name: "Package Name", sortable: true, sortedOrder: "dsc" },
  { name: "ID", sortable: false },
  { name: "Sub Plan", sortable: false },
  { name: "Plan Cost", sortable: false },
  { name: "Duration", sortable: true },
  // { name: "Offer", sortable: false },
  { name: "Speed", sortable: true },
  // { name: "No. of Users", sortable: false },
  { name: "Static Ip", sortable: true },
  { name: "Plan Type", sortable: true },
  { name: "Payment Type", sortable: true },

  // { name: "Upload Speed", sortable: true },
  // { name: "Package Data Type", sortable: true },
  // { name: "FUP Calculation", sortable: true },
  // { name: "Fallback Plan", sortable: true },
  // { name: "Billing Cycle", sortable: true },
  // { name: "Status", sortable: true },
  // { name: "Plan Cost", sortable: true },
  // { name: "Plan CGST %", sortable: true },
  // { name: "Plan SGST %", sortable: true },
  // { name: "Total Plan Cost", sortable: true },
  // { name: "Renewal Expiry Day", sortable: true },
  // { name: "Time Unit", sortable: true },
];

export default servicePlanTableHeaders;
